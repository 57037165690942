import React from 'react'
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";


class SideBarTemplate extends React.Component {
  render() {
    console.log("BlogRoll", this.props)
    const { data } = this.props

    const imageStyle = { borderRadius: "5px" };
    const alt = "portrait";

    return (
      <div class="menu has-text-black sidebar">
        <p class="menu-label">
          Profile
        </p >
        <div class="menu-list">
          <p class="menu-list">
            <GatsbyImage
              image={data.markdownRemark.frontmatter.socialImage.childImageSharp.gatsbyImageData}
              style={imageStyle}
              alt={alt}
            />
          </p>
          <p><span className="textbold">名前：Yoshi</span></p >
          <p>理想のくらしコーチ。</p>
          <p><span className="textred">週7でやりたいことを全力で楽しんで、特別なイベントがなくても幸せを感じる。
            自分を受け入れられるようになったり、うつを克服したり、揺るぎない自信を持てている。</span></p>
          <p>もともとなるべく自分や現実から目をそむけ、能力のない自分は無価値であるという価値観。</p>
          <p>そこから、大学受験勉強でうつ → 第一志望より2ランク以上落ちた大学に入学し自己否定が悪化
            → 人と関わりたくない。何をしても楽しめない → 心も身体もボロボロで、結局現実逃避して自分が嫌いになる地獄のループ。</p>
          <p><span className="textbold">自分と向き合っていくが、それを楽しめるだけでなく少しずつ気持ちが軽くなる。</span>
            <span className="textred">自分を受け入れるように継続することで、自信が湧いてきて価値観が完全に崩壊。</span></p>
          <p>人見知りだし自分の意見を言うのも難しくなるべく人と関わらないようにしていたのに、
            やりたいことのために知らない人だけの立食パーティーであれ、相手が社長だろうが関係なく飛び込む。</p>
          <p>興味を持てることなんてなかったのに、好奇心から本や様々な人から勉強をすること事態が面白いと感じられるように。</p>
          <p>できるだけ楽をしたいナマケモノで継続なんて考えたこともない私がどう変わってきたのかを理念も含めてもっと詳しく知りたい人はこちら↓</p>
          <p class="aligncenter">
            <Link to="/blog/concept/">
              <button class="button is-link is-focused">さらに詳しく見る</button>
            </Link>
          </p>

          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <p class="menu-label">
          初めての方へ
        </p>
        <ul class="menu-list">
          <li>
            初めての方は<Link to="/blog/concept/">このブログの運営理念</Link>
            (※2022年5月最新版)をまずは読んで欲しいです。
          </li>
        </ul>
        <p class="menu-label">
          YouTube
        </p>
        <ul class="menu-list">
          <iframe title="youtube-subscribe-btn" frameborder="0" src="https://www.youtube.com/subscribe_embed?usegapi=1&amp;channelid=UCzPZkC4BQDUdQ1kD0AUj0Qw&amp;layout=full&amp;count=hidden" data-gapiattached="true"></iframe>
        </ul>
        <p class="menu-label">
          {/**人気記事*/}
        </p>
      </div >

    )
  }
}

SideBar.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};


export default function SideBar() {
  return (
    <StaticQuery
      query={graphql`
      query SideBarQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }){
          frontmatter {
            socialImage {
              childImageSharp {
                gatsbyImageData(                        
                  width: 100
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    `}
      render={(data) => <SideBarTemplate data={data} />}
    />
  )
};

